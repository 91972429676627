import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import React, { Suspense, lazy, Fragment, Component } from 'react';
import { Spinner } from 'reactstrap';
import { connect } from 'react-redux';
import { refreshToken } from '../../reducers/StaffAuth';
import Login from '../../Containers/Login';
import AppHeader from '../../Layout/AppHeader';
import AppSidebar from '../../Layout/AppSidebar';
import '../../assets/css/themify-icons.css';
import '../../assets/css/feather.css';
import '../../assets/css/style.css';

const Dashboards = lazy(() => import('../../Containers/Dashboards'));
const Groups = lazy(() => import('../../Containers/Groups'));
const Students = lazy(() => import('../../Containers/Students'));
const Assignments = lazy(() => import('../../Containers/Assignments'));
const Streams = lazy(() => import('../../Containers/Streams'));
const Settings = lazy(() => import('../../Containers/Settings'));
const Guides = lazy(() => import('../../Containers/Guides'));
const Feedback = lazy(() => import('../../Containers/Feedback'));
const Conferences = lazy(() => import('../../Containers/Conferences'));
const Video = lazy(() => import('../../Containers/Video'));
const quizzes = lazy(() => import('../../Containers/Quiz'))
const reports = lazy(() => import('../../Containers/Reports'))
const StudyMaterial = lazy(() => import('../../Containers/StudyMaterial'));


class Main extends Component {
    componentDidMount() {
        this.props.refreshToken();
    }

    render() {
        if (!this.props.StaffAuth.loggedIn) return (<Login />);

        return (
            <Fragment>
                <div className='app-header header-text-dark header-shadow'>
                <AppHeader type="Student" />
                </div>


                <div className="app-main">
                    <AppSidebar />
                        <div className="app-main__outer">

                            {/* Dashboards */}
                            <Suspense fallback={
                                <div className="loader-container">
                                    <Spinner size="lg" color="primary" />
                                </div>
                            }>
                                <Route path="/dashboard" component={Dashboards} />
                            </Suspense>

                            {/* Assignments */}
                            <Suspense fallback={
                                <div className="loader-container">
                                    <Spinner size="lg" color="primary" />
                                </div>
                            }>
                                <Route path="/assignments" component={Assignments} />
                            </Suspense>

                             {/* Study Material */}
                            <Suspense fallback={
                                <div className="loader-container">
                                    <Spinner size="lg" color="primary" />
                                </div>
                            }>
                                <Route path="/study-material" component={StudyMaterial} />
                            </Suspense>

                            {/* Streams */}
                            <Suspense fallback={
                                <div className="loader-container">
                                    <Spinner size="lg" color="primary" />
                                </div>
                            }>
                                <Route path="/streams" component={Streams} />
                            </Suspense>

                            {/* Quiz */}
                            <Suspense fallback={
                                <div className="loader-container">
                                    <Spinner size="lg" color="primary" />
                                </div>
                            }>
                                <Route path="/quiz" component={quizzes} />
                            </Suspense>

                            {/* Reports */}
                            <Suspense fallback={
                                <div className="loader-container">
                                    <Spinner size="lg" color="primary" />
                                </div>
                            }>
                                <Route path="/reports" component={reports} />
                            </Suspense>

                            {/* Settings */}
                            <Suspense fallback={
                                <div className="loader-container">
                                    <Spinner size="lg" color="primary" />
                                </div>
                            }>
                                <Route path="/settings" component={Settings} />
                            </Suspense>

                            {/* Guides */}
                            <Suspense fallback={
                                <div className="loader-container">
                                    <Spinner size="lg" color="primary" />
                                </div>
                            }>
                                <Route path="/guides" component={Guides} />
                            </Suspense>

                            {/* Feedback */}
                            <Suspense fallback={
                                <div className="loader-container">
                                    <Spinner size="lg" color="primary" />
                                </div>
                            }>
                                <Route path="/feedback" component={Feedback} />
                            </Suspense>

                            {/* Conferences */}
                            <Suspense fallback={
                                <div className="loader-container">
                                    <Spinner size="lg" color="primary" />
                                </div>
                            }>
                                <Route path="/conferences" component={Conferences} />
                            </Suspense>

                            {/* video */}
                            <Suspense fallback={
                                <div className="loader-container">
                                    <Spinner size="lg" color="primary" />
                                </div>
                            }>
                                <Route path="/video" component={Video} />
                            </Suspense>


                            <Route exact path="/" render={() => (
                                <Redirect to="/dashboard" />
                            )} />
                        </div>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProp = state => ({
    StaffAuth: state.StaffAuth,
    Setup: state.Setup,
});

const mapDispatchToProps = dispatch => {
    return {
        refreshToken: () => refreshToken(dispatch),
    }
}

export default connect(mapStateToProp, mapDispatchToProps)(Main);
