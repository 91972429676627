import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import UserBox from './Components/UserBox';
import { SUBDOMAIN_KEY } from '../../config/constants';
import HeaderLogo from '../AppLogo';

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
        };
    }
    render() {
        let {
            headerBackgroundColor,
            enableMobileMenuSmall,
            enableHeaderShadow
        } = this.props;
        
        return (
            <Fragment>
                    <HeaderLogo/>
                    <div className={cx(
                        "app-header__content",
                        {'header-mobile-open': enableMobileMenuSmall},
                    )}>
                        <div className="app-header-right">
                            <UserBox type={this.props.type} />
                        </div>
                    </div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    enableHeaderShadow: state.ThemeOptions.enableHeaderShadow,
    closedSmallerSidebar: state.ThemeOptions.closedSmallerSidebar,
    headerBackgroundColor: state.ThemeOptions.headerBackgroundColor,
    enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
    StaffAuth: state.StaffAuth,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
