import React, { Component, Fragment } from 'react';
import { Form, FormFeedback, Input, Label, Spinner } from 'reactstrap';
import { connect } from 'react-redux';
import { loginUser } from '../../reducers/StaffAuth';
import { SUBDOMAIN_KEY } from '../../config/constants';
import { requestOtpApi, verifyOtpApi, resetPasswordApi } from '../../api/auth_api';
import { toast } from 'react-toastify';
import Logo from '../../assets/utils/images/Horizontal_Logo.png';
import BG from '../../assets/utils/images/login.jpg';
import '../../assets/css/themify-icons.css';
import '../../assets/css/feather.css';
import '../../assets/css/style.css';


const LOGIN = 'LOGIN';
const REQUEST_OTP = 'REQUEST_OTP';
const VERIFY_OTP = 'VERIFY_OTP';
const RESET_PASSWORD = 'RESET_PASSWORD';

class Login extends Component {
  state = {
    username: '',
    password: '',
    password_confirmation: '',
    mobile: '',
    subdomain: '',
    otp: '',
    token: '',
    showPassword: false,
    screen: LOGIN,
  }

  showHidePassword(){
    if(!this.state.showPassword){
      this.setState({showPassword: true});
    }else{
      this.setState({showPassword: false});
    }
  }

  componentDidMount() {
    const subdomain = localStorage.getItem(SUBDOMAIN_KEY);
    this.setState({ subdomain });
  }

  handleEnterPress(e) {
    if(e.charCode === 13) {
      switch (this.state.screen) {
        case LOGIN:
          this.props.loginUser({ username: this.state.username, password: this.state.password });
          break;
        case REQUEST_OTP:
          this.requestOtp();
          break;
        case VERIFY_OTP:
          this.verifyOtp();
          break;
        case RESET_PASSWORD:
          this.resetPassword();
          break;
        default:
        break;
      }
    }
  }

  setSubdomain(subdomain) {
    localStorage.setItem(SUBDOMAIN_KEY, subdomain);
    this.setState({ subdomain });
  }

  requestOtp() {
    this.setState({ loading: true });
    requestOtpApi({
      mobile_number: this.state.username,
    })
    .then((data) => {
      toast.success(data.message);
      this.setState({ loading: false, screen: VERIFY_OTP, mobile_number: data.mobile_number, password: '' });
    })
    .catch((e) => {
      this.setState({ loading: false });
      if (e.data) {
        if (e.data.insufficient_sms_credits || !e.data.otp_reset_enable) {
          toast.error('OTP password reset disabled. Please contact our support team for assistance');
          return;
        }
      }
      toast.error(e.message);
    });
  }

  verifyOtp() {
    this.setState({ loading: true });
    verifyOtpApi({
      mobile_number: this.state.mobile_number,
      otp: this.state.otp,
      call_func: 'generate_forgot_token',
      func_data: {
        mobile_number: this.state.username
      },
    })
    .then((res) => {
      toast.success(res.message);
      this.setState({ loading: false, token: res.token, screen: RESET_PASSWORD, otp: '' });
    })
    .catch(() => {
      toast.error('Unable to verify OTP');
      this.setState({ loading: false });
    });
  }

  resetPassword() {
    if (this.state.password === this.state.password_confirmation && this.state.password.length >= 8) {
      this.setState({ loading: true });
      resetPasswordApi({
        password: this.state.password,
        password_confirmation: this.state.password_confirmation,
        email: this.state.username,
        mobile_number: this.state.username,
        token: this.state.token,
      })
      .then((res) => {
        toast.success('Password reset successfully');
        this.setState({ loading: false, screen: LOGIN });
      })
      .catch((e) => {
        toast.error('Unable to Reset Password');
        this.setState({ loading: false });
      });
    } else {
      toast.error('Password and Confirm Password must be same and at least 8 characters long.');
    }
  }

  renderLogin() {
    return(
      <div class="border-0 ml-auto mr-auto login-card">
        <div class="">
            <div class="fw-700 text-center display2-md-size mb-3" style={{fontSize: "24px"}}>Login into your account</div>
              <Form className="mt-4">
                <Label className="text-muted">Organization Code</Label>
                <div class="form-group icon-input mb-3">
                    <i class="font-sm ti-user text-grey-500 pr-0"></i>
                    <Input class="style2-input pl-5 form-control text-grey-900 font-xsss fw-600" value={this.state.subdomain} onChange={(e) => this.setSubdomain(e.target.value)} onKeyPress={(e) => this.handleEnterPress(e)} placeholder="Enter Organization Code" />
                </div>

                <Label className="text-muted">Username</Label>
                <div class="form-group icon-input mb-3">
                    <i class="font-sm ti-email text-grey-500 pr-0"></i>
                    <Input class="style2-input pl-5 form-control text-grey-900 font-xsss fw-600" invalid={this.props.StaffAuth.loginError} value={this.state.username} onChange={(e) => this.setState({ username: e.target.value })} onKeyPress={(e) => this.handleEnterPress(e)} placeholder="Enter Username" />
                </div>

                <Label className="text-muted">Password</Label>
                <div class="form-group icon-input mb-1">

                <i class="font-sm ti-eye text-grey-500 pr-0" onClick={()=> this.showHidePassword()} style={{ left: "auto", right: "15px" }}></i>
                  <Input class="style2-input pl-5 form-control text-grey-900 font-xss ls-3" invalid={this.props.StaffAuth.loginError} type={ (!this.state.showPassword) ? 'password' : 'text'} value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })} onKeyPress={(e) => this.handleEnterPress(e)} placeholder="Enter Password"/>
                    <i class="font-sm ti-lock text-grey-500 pr-0"></i>
                </div>

                <FormFeedback>Unable to login</FormFeedback>
                <div class="form-check text-left mb-3">
                    <input type="checkbox" class="form-check-input mt-2" id="exampleCheck1" />
                    <label class="form-check-label font-xsss text-grey-500" for="exampleCheck1">Remember me</label>
                    <a style={{cursor: 'pointer'}} class="fw-600 font-xsss text-grey-700 mt-1 float-right" onClick={() => this.setState({ screen: REQUEST_OTP })}>Forgot your Password?</a>
                </div>
              </Form>
             
            <div class="col-sm-12 p-0 text-left">

            {
              (this.props.StaffAuth.loggingIn) ?
              <Spinner size="lg" color="primary" /> :
                <div class="form-group mb-1"><a onClick={() => this.props.loginUser({ username: this.state.username, password: this.state.password })} class="form-control text-center style2-input text-white fw-600 bg-dark border-0 p-0" style={{ cursor: "pointer" }}>Login</a></div>
              }
            </div>
        </div>
    </div>
    );
  }

  renderRequestOtp() {
    return(
      <div class="card shadow-none border-0 ml-auto mr-auto login-card">
      <div class="card-body rounded-0 text-left">
        <h2 class="fw-700 display1-size display2-md-size mb-3">Forgot Password</h2>
        <Form className="mt-4">

          <Label className="text-muted">Organization Code</Label>
          <div class="form-group icon-input mb-3">
              <i class="font-sm ti-user text-grey-500 pr-0"></i>
              <Input class="style2-input pl-5 form-control text-grey-900 font-xsss fw-600" value={this.state.subdomain} onChange={(e) => this.setSubdomain(e.target.value)} onKeyPress={(e) => this.handleEnterPress(e)} placeholder="Enter Organization Code" />
          </div>

          <Label className="text-muted">Mobile Number</Label>
          <div class="form-group icon-input mb-3">
              <i class="font-sm ti-user text-grey-500 pr-0"></i>
              <Input class="style2-input pl-5 form-control text-grey-900 font-xsss fw-600" value={this.state.username} onChange={(e) => this.setState({ username: e.target.value })} onKeyPress={(e) => this.handleEnterPress(e)} placeholder="Enter Mobile Number" />
          </div>
        </Form>
        {
          (this.state.loading) ?
          <Spinner size="lg" color="primary" /> :
          <div class="form-group mb-1"><a onClick={() => this.requestOtp()} class="form-control text-center style2-input text-white fw-600 bg-dark border-0 p-0 ">Request OTP</a></div>
        }


        <div class="form-check text-left mb-3">
            <a style={{cursor: 'pointer'}} class="fw-600 font-xsss text-grey-700 mt-1 float-right" onClick={() => this.setState({ screen: LOGIN })}>Back To Login</a>
        </div>
      </div>
      </div>
    );
  }

  renderVerifyOtp() {
    return(
    <div class="card shadow-none border-0 ml-auto mr-auto login-card">
      <div class="card-body rounded-0 text-left">
      <h2 class="fw-700 display1-size display2-md-size mb-3">Verify OTP</h2>
        <div className="mt-4">
          <Label className="text-muted">OTP</Label>
          <div class="form-group icon-input mb-3">
              <i class="font-sm ti-user text-grey-500 pr-0"></i>
              <Input class="style2-input pl-5 form-control text-grey-900 font-xsss fw-600" value={this.state.otp} onChange={(e) => this.setState({ otp: e.target.value })} onKeyPress={(e) => this.handleEnterPress(e)} placeholder="Enter OTP"/>
          </div>
        </div>
        {
          (this.state.loading) ?
          <Spinner size="lg" color="primary" /> :
          <div class="form-group mb-1"><a onClick={() => this.verifyOtp()} class="form-control text-center style2-input text-white fw-600 bg-dark border-0 p-0 ">Verify OTP</a></div>
        }

        <div class="form-check text-left mb-3">
            <a style={{cursor: 'pointer'}} class="fw-600 font-xsss text-grey-700 mt-1 float-right" onClick={() => this.setState({ screen: REQUEST_OTP })}>Back</a>
        </div>

      </div>
      </div>
    );
  }

  renderResetPassword() {
    return(
      <div class="card shadow-none border-0 ml-auto mr-auto login-card">
      <div class="card-body rounded-0 text-left">
      <h2 class="fw-700 display1-size display2-md-size mb-3">Change Password</h2>
        <Form className="mt-4">

        <Label className="text-muted">Password</Label>
          <div class="form-group icon-input mb-3">
              <i class="font-sm ti-user text-grey-500 pr-0"></i>
              <Input class="style2-input pl-5 form-control text-grey-900 font-xsss fw-600" type="password" value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })} onKeyPress={(e) => this.handleEnterPress(e)} placeholder="Enter Password"/>
          </div>

          <div class="form-group icon-input mb-3">
              <Label className="text-muted">Confirm Password</Label>
              <Input class="style2-input pl-5 form-control text-grey-900 font-xsss fw-600" type="password" value={this.state.password_confirmation} onChange={(e) => this.setState({ password_confirmation: e.target.value })} onKeyPress={(e) => this.handleEnterPress(e)} placeholder="Re-Enter Password"/>
          </div>
        </Form>
        {
          (this.state.loading) ?
          <Spinner size="lg" color="primary" /> :
          <div class="form-group mb-1"><a onClick={() => this.resetPassword()} class="form-control text-center style2-input text-white fw-600 bg-dark border-0 p-0 ">Reset Password</a></div>
        }


        <div class="form-check text-left mb-3">
            <a style={{cursor: 'pointer'}} class="fw-600 font-xsss text-grey-700 mt-1 float-right" onClick={() => this.setState({ screen: REQUEST_OTP })}>Back</a>
        </div>

      </div>
      </div>
    );
  }

  render() {
    return (
      <Fragment>
          {
            (this.props.StaffAuth.refreshingToken) ?
            <Spinner size="lg" color="primary" /> :
            <div className="main-wrap" style={{background: "#FFFFFF"}}>
              <div className="row">
              <div className="col-xl-6 d-none d-xl-block">
                <img src={BG}/>
              </div>
              <div className="col-xl-6 align-items-center bg-white rounded-lg">
                <div className="text-center mt-5 mb-2"><img src={Logo} alt="logo" style={{ width: 240 }} /></div>
                {this.state.screen === LOGIN && this.renderLogin()}
                {this.state.screen === REQUEST_OTP && this.renderRequestOtp()}
                {this.state.screen === VERIFY_OTP && this.renderVerifyOtp()}
                {this.state.screen === RESET_PASSWORD && this.renderResetPassword()}
              </div>
              </div>
            </div>
          }
      </Fragment>
    );
  }
}

const mapStateToProp = state => ({
    StaffAuth: state.StaffAuth,
});

const mapDispatchToProps = dispatch => {
  return {
    loginUser: (payload) => loginUser(dispatch, payload),
  }
}

export default connect(mapStateToProp, mapDispatchToProps)(Login);

const styles = {
  imageOverlaySmallText: {
    color: '#fff',
    textAlign: 'left',
    fontFamily: 'Arima Madurai, cursive',
    fontSize: 28,
  },
  imageOverlayText: {
    color: '#fff',
    textAlign: 'left',
    fontFamily: 'Arima Madurai, cursive',
    fontSize: 46,
    marginTop: 25,
  },
  orangeText: {
    color: '#f56f1a'
  },
  bottomTextStyle: {
    color: '#fff',
    textAlign: 'right',
    fontSize: 12
  }
}
